/* eslint-disable react/jsx-newline */
import * as React from 'react'

import imgPlanif from '@assets/images/backgrounds/planificateur-sante.png'
import { Box, Center, Flex, HStack, Heading, Icon, Image, Text, useMediaQuery } from '@chakra-ui/react'
import Seo from '@components/Seo'
import LogoIcon from '@components/core/LogoIcon'
import Section from '@components/core/Section'
import { CtaButton } from '@components/ui/Button'
import { Card, FlippingCard, HorizontalCard } from '@components/ui/Card'
import { HorizontalCarousel } from '@components/ui/Carousel'
import { MobileDisplay } from '@components/ui/Display'
import IconItemList from '@components/ui/IconItemList'
import IconLink from '@components/ui/IconLink'
import MenuSticky from '@components/ui/MenuSticky'
import { functionalities, horizontalCarouselContent, mission, specificities } from '@data/lp/planning-personnel'
import { CARD_MIN_HEIGHT } from '@helpers/constants'
import { CALENDLY_URL } from '@helpers/url'
import { graphql } from 'gatsby'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { HiArrowRight } from 'react-icons/hi'
import { v4 as uuidv4 } from 'uuid'

const PersonalPlanningPage = () => {
    const [isTablet, isBigTablet] = useMediaQuery(['(min-width: 1000px)', '(min-width: 1200px)'])

    return (
        <>
            <Seo
                title="Logiciel Planning Personnel"
                description="Découvrez Staffea pour la gestion de vos plannings ➜ ✓ Dématérialisation des process ✓ Automatisation des tâches ✓ Gestion en temps réel"
            />

            <main>
                <MenuSticky>
                    <Section
                        bg="indigo.100"
                        id="personal-planning-staffea-section"
                        name="staffea"
                        backgroundImage={isTablet ? imgPlanif : undefined}
                        backgroundPosition="right top"
                        backgroundRepeat="no-repeat"
                        backgroundSize="contain"
                        aria-label="Homme responsable de la planification utilise l'application web Staffea"
                    >
                        <Flex direction="column" align="start" textAlign="left" maxW="850" py={20}>
                            <Heading
                                as="h1"
                                fontWeight="bold"
                                color="indigo.500"
                                mb={10}
                                maxW={isTablet ? '80%' : '100%'}
                            >
                                Logiciel de planning du personnel
                            </Heading>

                            <Text color="indigo.500" mb="12" maxW={isTablet ? '65%' : '650'}>
                                Optimisez la gestion administrative de votre entreprise grâce à un logiciel de gestion
                                de planning RH agile, efficace et sécurisé, basé sur l&#39;intelligence artificielle et
                                le machine learning ! Sa prise en main facile vous assure une gestion facilitée de tous
                                les aspects de la planification.
                                <br />
                                <br /> Création de roulements type, modifications en temps réel, génération de modèles
                                de contrats de travail, contrôle de la conformité des plannings, réception des demandes
                                de congés et des arrêts maladies : votre gestion de planning n&#39;aura jamais été aussi
                                facile qu&#39;avec Staffea !
                            </Text>

                            <Center alignSelf={!isTablet ? 'center' : 'inherit'}>
                                <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                    <CtaButton
                                        text="Demander une démo"
                                        description="Démonstration de 45 minutes en visioconférence"
                                    />
                                </a>
                            </Center>
                        </Flex>
                    </Section>

                    <Section bg="white" id="personal-planning-interoperability-section" name="interopérabilité">
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={4}>
                                Une solution de planning qui s&#39;adapte aux spécificités de votre métier.
                            </Text>

                            <Text color="indigo.500" fontSize="sm" mb={10} px="8">
                                Tous vos besoins métier sont couverts par Staffea. Complète, elle vous offre de
                                nombreuses fonctionnalités et une grande souplesse pour répondre à vos attentes et à
                                celles de votre personnel.
                            </Text>

                            <Flex
                                columnGap={6}
                                rowGap={12}
                                wrap="wrap"
                                justifyItems="stretch"
                                alignItems="stretch"
                                margin="auto"
                                width="100%"
                                display="grid"
                                gridAutoFlow={isBigTablet ? 'column dense' : 'row dense'}
                            >
                                {specificities.map(({ id, color, name, description, fields }) => (
                                    <FlippingCard
                                        key={id}
                                        front={
                                            <Card size="auto" p="8" minH={CARD_MIN_HEIGHT} height="100%">
                                                <LogoIcon color={color} mb="3" />

                                                <Text as="h3" fontWeight="bold" color={color}>
                                                    {`Staffea ${name}`}
                                                </Text>

                                                <Text fontWeight="bold" color="indigo.500" mb={4}>
                                                    {fields.join(', ')}.
                                                </Text>

                                                <Text fontSize="sm" color="indigo.500">
                                                    {description}
                                                </Text>
                                            </Card>
                                        }
                                        back={
                                            <Card size="auto" p="8" bg={color} minH={CARD_MIN_HEIGHT} height="100%">
                                                <Flex direction="column" justify="space-around" minH="315px">
                                                    <Text color="white" fontWeight="bold" align="center">
                                                        STAFFEA POUR VOUS
                                                    </Text>

                                                    <Box color="white" textAlign="center">
                                                        <Text variant="inline">Staffea&nbsp;</Text>

                                                        <Text variant="bold">
                                                            réduit drastiquement le temps de gestion des plannings
                                                        </Text>

                                                        <Text variant="inline">
                                                            &nbsp;de vos équipes. Découvrez comment notre outil
                                                            s&#39;adapte à vos spécificités&nbsp;
                                                        </Text>

                                                        <HStack justify="center">
                                                            <Text variant="bold" textTransform="lowercase">
                                                                {name}
                                                            </Text>

                                                            <Icon as={AiOutlineArrowDown} color="white" w="5" h="5" />
                                                        </HStack>
                                                    </Box>
                                                </Flex>

                                                <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                                    <CtaButton
                                                        text="Demander une démo !"
                                                        color="white"
                                                        textColor={color}
                                                        hoverColor="indigo.500"
                                                    />
                                                </a>
                                            </Card>
                                        }
                                    />
                                ))}
                            </Flex>
                        </Box>
                    </Section>

                    <Section id="personal-planning-features-section" name="fonctionnalités">
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500">
                                Des modules complémentaires
                                <Text as="span" color="green.500" display="block">
                                    pour optimiser votre gestion du personnel.
                                </Text>
                            </Text>

                            <Flex my="10" gap="20" direction="column" mx="auto">
                                {functionalities.map(({ id, subTitle, title, image, images, list, linkText, link }) =>
                                    !isTablet && images ? (
                                        <Box key={id} w={['xs', 'sm']} mx="auto">
                                            <Box>
                                                <Box>
                                                    <Text fontSize="sm" fontWeight="bold" color="green.500">
                                                        {subTitle}
                                                    </Text>

                                                    <Text as="h3" fontWeight="bold" color="indigo.500" mb="4">
                                                        {title}
                                                    </Text>

                                                    <IconItemList list={list} />

                                                    {link ? (
                                                        <IconLink
                                                            text={
                                                                linkText ||
                                                                `Notre logiciel de ${title.toLocaleLowerCase()}`
                                                            }
                                                            to={link}
                                                            icon={HiArrowRight}
                                                            pt="4"
                                                        />
                                                    ) : null}
                                                </Box>

                                                <Flex
                                                    mt="8"
                                                    direction="column"
                                                    align="center"
                                                    gap="35"
                                                    flexDirection="row"
                                                    justifyContent="center"
                                                >
                                                    {images.map((image) => (
                                                        <MobileDisplay
                                                            width="125px"
                                                            height="261px"
                                                            key={uuidv4()}
                                                            transform="scale(0.3) translateX(-137px) translateY(-290px)"
                                                            media={<Image src={image.image} alt={image.alt} />}
                                                        />
                                                    ))}
                                                </Flex>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <HorizontalCard
                                            key={id}
                                            w={['xs', 'sm', '2xl', '3xl', '4xl']}
                                            mediaW={['xs', 'sm', 'md']}
                                            contentW={['xs', 'sm', 'md']}
                                            mediaFlexBasis={['50%', '50%', '50%', '70%']}
                                            isReverse={id % 2 === 0}
                                            gap={['4', '8']}
                                            hasCenteredContent={true}
                                            media={
                                                images ? (
                                                    <Flex gap="4" mx="auto" transform="translateX(-20px)">
                                                        {images.map((image) => (
                                                            <MobileDisplay
                                                                width="125px"
                                                                height="360px"
                                                                key={uuidv4()}
                                                                transform="scale(0.3) translateX(-137px) translateY(-120px)"
                                                                media={<Image src={image.image} alt={image.alt} />}
                                                            />
                                                        ))}
                                                    </Flex>
                                                ) : (
                                                    <Image
                                                        src={image}
                                                        objectFit="cover"
                                                        maxW="60"
                                                        bgColor={'indigo.100'}
                                                        px={30}
                                                    />
                                                )
                                            }
                                            title={
                                                <Box>
                                                    <Text fontSize="sm" fontWeight="bold" color="green.500">
                                                        {subTitle}
                                                    </Text>

                                                    <Text as="h3" fontWeight="bold" color="indigo.500" mb="4">
                                                        {title}
                                                    </Text>
                                                </Box>
                                            }
                                            content={<IconItemList list={list} />}
                                            footer={
                                                link ? (
                                                    <IconLink
                                                        text={
                                                            linkText || `Notre logiciel de ${title.toLocaleLowerCase()}`
                                                        }
                                                        to={link}
                                                        icon={HiArrowRight}
                                                        pt="4"
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )
                                )}
                            </Flex>

                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500">
                                Les points forts de Staffea
                                <Text as="span" color="green.500" display="block">
                                    pour séréniser la gestion de planning du personnel.
                                </Text>
                            </Text>

                            <HorizontalCarousel
                                content={horizontalCarouselContent.map(({ header, title, text, image, alt }) => {
                                    return {
                                        title: header,
                                        description: (
                                            <>
                                                <Text color="indigo.500" fontSize="xs" align="start" mb="4">
                                                    {title}
                                                </Text>

                                                <Text color="indigo.500" fontSize="xs" align="start">
                                                    {text}
                                                </Text>
                                            </>
                                        ),
                                        image: <Image key={uuidv4()} src={image} alt={alt} />,
                                    }
                                })}
                            />
                        </Box>
                    </Section>

                    <Section bg="indigo.100" id="personal-planning-mission-section" name="mission">
                        <Box py={20}>
                            <Center>
                                <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500">
                                    La mission de Staffea ?&nbsp;
                                    <Text as="span" color="green.500">
                                        Vous faire gagner du temps.
                                    </Text>
                                </Text>
                            </Center>

                            <Flex gap="14" direction="row" wrap="wrap" mt="8" justify={'center'}>
                                {mission.map(({ id, title, content, svg }) => (
                                    <Flex key={id} direction="column" maxW="290px">
                                        <Image src={svg} maxW="250px" objectFit="cover" mb="2" />

                                        <Text as="h3" color="green.500" fontSize="sm" fontWeight="bold" mb="4">
                                            {title}
                                        </Text>

                                        {content.map((parahraph) => (
                                            <Text key={uuidv4()} color="indigo.500" fontSize="xs" mb="4">
                                                {parahraph}
                                            </Text>
                                        ))}
                                    </Flex>
                                ))}
                            </Flex>
                        </Box>
                    </Section>
                </MenuSticky>
            </main>
        </>
    )
}

export default PersonalPlanningPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["homepage"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
